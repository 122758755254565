<template>
  <div>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="configH.m"
        :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
        :id="configH.id"
        :opt="configH.opt"
        :initData="configH.initData || {}"
      />
    </portal>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:title>
        <v-row class="ma-1">
          <v-btn-toggle v-if="getAccess('leads.viewAll')" v-model="typeView" mandatory borderless dense class="mr-2">
            <v-btn :value="1" color="#d0bf9c">
              <span class="hidden-lg-and-down">Лиды</span>
              <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-filter </v-icon>
            </v-btn>
            <v-btn :value="2" class="ml-1" color="#d0bf9c">
              <span class="hidden-lg-and-down">Задачи</span>
              <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-clock </v-icon>
            </v-btn>
            <v-btn :value="3" class="ml-1" color="#d0bf9c">
              <span class="hidden-lg-and-down">Чат</span>
              <v-icon :right="$vuetify.breakpoint.lgAndUp"> fas fa-comments </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn v-if="typeView == 1" color="primary" @click="onChangeView()">
            <btn-title v-if="view == 1" icon="fas fa-columns">Вид канбан</btn-title>
            <btn-title v-else icon="fas fa-list">Вид таблица</btn-title>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
        <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
      </template>

      <a-table-f-api v-if="view == 1 && typeView == 1" ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
      <view-canban
        ref="taskCanban"
        v-if="view == 2 && typeView == 1"
        :api="url"
        :model="model"
        @show-card="idItemShow = $event"
        @click-task="onClickTask($event)"
        @click-action="onClickAction($event)"
      />
      <a-table-f-api
        v-if="typeView == 2"
        ref="table"
        :api="m.tasks.api"
        :model="getModelList(m.tasks)"
        :useQuery="false"
        :defaults="{
          filters: { user_id: getAccess(`leads.viewAll`) || id ? undefined : $root.profile.id },
          sort: [
            { key: 'status', order: 'ASC' },
            { key: 'id', order: 'DESC' },
          ],
          paramName: 'leadActions',
        }"
        @click1="onClickRow($event)"
      >
        <template v-slot:item.actions="{ row }">
          <div class="d-flex">
            <v-btn v-if="row.status == 1" x-small fab class="ma-0 ml-0" color="green" title="Выполнить" @click.stop="onClickTaskAction({ task: row, type: 'done' })">
              <v-icon small>fas fa-check</v-icon>
            </v-btn>
          </div>
        </template>
      </a-table-f-api>
      <ChatRoom v-if="typeView == 3" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import mainView from "@/components/libs/mainView";
import { getAccess, genModel, doubleClickDetect, keyDetect, popupMenu, autoHeightBlock } from "@/components/mixings";
export default {
  mixins: [mainView, getAccess, genModel, doubleClickDetect, keyDetect, popupMenu, autoHeightBlock],
  components: {
    baseChats: () => import("./chat3/baseChats.vue"),
    viewCanban: () => import("@/views/dialogs/leadsCanban.vue"),
    ViewItem: () => import("./views/leadView"),
    ChatRoom: () => import("./chat3/ChatRoom"),
  },
  data() {
    return {
      idEdit: 0,
      view: 1,
      typeView: 1,
      showEditDialog: false,
      configH: null,
      title: "",
      m: this.$store.getters["config/get"].models.leads,
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: { user_id: this.getAccess("leads.viewAll") ? undefined : this.$root.profile.id },
      },
    };
  },
  computed: {
    url() {
      return this.m.api;
    },
    model() {
      return this.getModelList(this.m);
    },
    configHead() {
      return this.m.config.manager || {};
    },
  },
  created() {
    this.$root.title = "Лиды";
  },
  watch: {
    showEditDialog(v) {
      if (!v) {
        if (this.configH.type == "lead") {
          if (this.view === 1) this.$refs.table.updateData();
        }
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      if (this.view === 1) this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    onChangeView() {
      if (this.view == 1) {
        this.view = 2;
      } else {
        this.view = 1;
      }
    },
    onClickRow(d) {
      this.idItemShow = d.row.id;
      return;
      this.showEditDialogFun(d.row.id);
    },

    onClickAction(lead) {
      const config = {};
      config.type = "actions";
      this.configH = null;
      config.head = this.m.actions.config.default || {};
      config.title = this.m.actions.config.default.title;
      config.api = this.m.actions.api;
      config.id = null;
      config.opt = {};
      config.initData = { lead_id: lead.id };
      config.m = this.m.actions;
      this.configH = config;
      this.showEditDialog = true;
    },
    onClickTaskAction(e) {
      if (e.type == "done") {
        this.onClickTask(e.task);
      }
    },
    onClickTask(task) {
      const config = {};
      config.type = "tasks";
      this.configH = null;
      config.head = this.m.actions.config.done || {};
      config.title = this.m.actions.config.done.title;
      config.api = this.m.actions.api;
      config.id = null;
      config.opt = this.getAccess("leads.actions.create") && task.status == 1 ? {} : { readonly: true };
      config.initData = { lead_id: task.lead_id, task_id: task.id, type: task.type };
      config.m = this.m.actions;
      this.configH = config;
      this.showEditDialog = true;
    },
    createNew() {
      this.showEditDialogFun(0);
    },

    showEditDialogFun(id) {
      const config = {};
      config.type = "lead";
      this.configH = null;
      config.head = this.m.config.manager || {};
      config.api = this.m.api;
      config.id = id;
      config.opt = {};
      config.title = this.m.config.manager.title;
      //config.initData = { lead_id: this.id };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;

      // this.idEdit = id;
      //this.showEditDialog = true;
    },
  },
};
</script>
